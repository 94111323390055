import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "C:/Users/pmaxh/Documents/node-projects/website/src/components/page-layout.js";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Video = makeShortcode("Video");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`With the power of Docker, ffmpeg, Node, and other technologies, you can make `}<em parentName="p">{`hilarious`}</em>{` videos like this!`}</p>
    <Video src="/videos/thanks-for-watching.mp4" mdxType="Video" />
    <p>{`To make your own:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`git clone https://github.com/pmaxhogan/thanks-for-watching
cd thanks-for-watching

# build and start
docker build -t thanks-for-watching .
docker run -d --rm --publish 8000:5000 --name thanks-for-watching thanks-for-watching:latest

# generate a video (replace with your own text)
curl -G --data-urlencode "$(echo -ne 'text=Top 10 Reasons to\\nLove Pineapple on Pizza')" http://localhost:8000/video --output out.mp4

# play your video
vlc out.mp4

# remove the container when you're done
docker rm --force thanks-for-watching
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      